import React, { useMemo } from 'react'
import { Link, graphql } from 'gatsby'
import Helmet from 'react-helmet'

import Layout from '../components/Layout'
import Posts from '../components/Posts'
import Lists from '../components/Lists'
import Projects from '../components/Projects'
import SEO from '../components/SEO'

import { getSimplifiedPosts } from '../utils/helpers'
import config from '../utils/config'

import projects from '../data/projects'
import interviews from '../data/interviews'
import speaking from '../data/speaking'
import cover from '../../content/images/cover.png'


export default function BlogIndex({ data }) {
  const latest = data.latest.edges
  const popular = data.popular.edges
  const simplifiedLatest = useMemo(() => getSimplifiedPosts(latest), [latest])
  const simplifiedPopular = useMemo(() => getSimplifiedPosts(popular), [
    popular,
  ])

  const Section = ({ title, children, ...props }) => (
    <section {...props}>
      <h2>{title}</h2>
      {children}
    </section>
  )

  return (
    <Layout>
      <Helmet title={config.siteTitle} />
      <SEO />
      <section className="lead">
        <div>
        <h2>Guide to Machine learning on AWS 🚀</h2>        
        <p className="subtitle">
          👋 Welcome to AWS Machine Learning Dev. Learn by building machine learning projects that solve a real world
          problem.
        </p>
        <p>
          We will be building machine learning projects from start to finish. Hopefully every month but some projects might take more time.
        </p>
        <h4>Who is this blog for?</h4>
        <ul>
          <li>You are interested in learning machine learning</li>
          <li>Looking for complete end to end projects</li>
          <li>Who likes to build projects and see them working</li>
        </ul>        
        <p>If you are looking for detailed theory on individual algorithms and the math behind it then this is probably not a good place. There
          are many awesome blogs about those topics and this is not one of them.
        </p>
        <p>
          You can read my <Link to="/blog">blog</Link>, view my{' '}
          <Link to="/guides">dev guides</Link>, or contact me at <b>hello</b> at{' '}
          <b>awsmachinelearning.dev</b>.
        </p>
        </div>        
        <div>        
          <img src={cover} alt="AWS Machinelearning Dev" />        
        </div>
      </section>
      <Section title="Latest">
        <Posts data={simplifiedLatest} tags />
      </Section>
      <Section title="Popular">
        <Posts data={simplifiedPopular} tags />
      </Section>
      {/* <Section title="Projects">
        <Projects data={projects} />
      </Section>
      <Section title="Interviews &amp; Podcasts" className="medium">
        <Lists data={interviews} />
      </Section>
      <Section title="Speaking" className="medium">
        <Lists data={speaking} />
      </Section> */}
      <Section title="Newsletter" className="small">
        <p>
          Every now and then I'll send out an email when I've created something
          new. Never any spam, easy unsubscribe whenever.
        </p>
        <a
          href="https://awsmachinelearningdev.substack.com/subscribe"
          target="_blank"
          rel="noreferrer"
        >
          Subscribe to the email list
        </a>        
      </Section>
      <Section>
      </Section>
    </Layout>
  )
}

export const pageQuery = graphql`
  query IndexQuery {
    latest: allMarkdownRemark(
      limit: 5
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { template: { eq: "post" } } }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            tags
          }
        }
      }
    }
    popular: allMarkdownRemark(
      limit: 20
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { categories: { eq: "Popular" } } }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            tags
          }
        }
      }
    }
  }
`
